//模块类型
export var contextTypeData={
  list:[{
    contextType:1,
    name:"标题模块"
  },{
    contextType:2,
    name:"图文内容"
  },{
    contextType:3,
    name:"资源文件"
  },{
    contextType:4,
    name:"标签设置",
    disabled:true
  },{
    contextType:5,
    name:"活动安排"
  },{
    contextType:6,
    name:"视频列表"
  }]
}

// 权限 1游客 2注册用户 3正式用户
export var auth={
  list:[{
    auth:1,
    name:"游客"
  },{
    auth:2,
    name:"注册用户"
  },{
    auth:3,
    name:"正式用户"
  }]
}

import _objectSpread from "/Users/lianxiang/star/htcode/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import { Search } from '@element-plus/icons-vue';
export default {
  name: 'Active',
  props: ['currentLeftData', 'courseData'],
  data: function data() {
    return {
      currentRow: null,
      structureId: "",
      contextmenuDialog: {
        //右键菜单定位
        left: 0,
        top: 0
      },
      isShowContextmenuDialog: false,
      //右键菜单是否显示
      courseList: [],
      courseId: this.courseData.id,
      containSublevel: 1,
      //是否包含子层级：1是 0否，默认：0
      replicateIds: [],
      // 用户信息
      userInfo: this.Ls.getParseToken("nof_userInfo"),
      // 筛选
      filter: [{
        placeholder: '教-学路径状态',
        value: '',
        type: 'select',
        list: [{
          id: '1',
          name: '正常'
        }, {
          id: '-1',
          name: '禁用'
        }]
      }, {
        placeholder: '教-学路径名称',
        value: '',
        type: 'text',
        list: []
      }],
      //左边菜单是否折叠
      isCollapse: false,
      //树形结构数据
      treeData: [],
      originTreeData: [],
      //树形结构数据
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      currentTree: this.$route.query.tree ? JSON.parse(this.$route.query.tree) : {},
      nowCheckData: {},
      // List接口返回表格数据
      tableKey: 1,
      //表格的key
      tableData: [],
      total: 0,
      //页数总数
      currentPage: 1,
      // 当前页数
      pageSize: 20,
      //每页个数
      // drawer抽屉-数据
      drawer: false,
      //是否显示弹框
      drawerState: '',
      direction: "rtl",
      drawerArticleState: "",
      drawerData: {
        areaId: '',
        conceptId: '',
        title: "",
        performance: '',
        //典型表现
        case: '',
        //案例展示
        activitys: [],
        //活动列表
        rank: "",
        state: 1
      },
      treeChangeList: [],
      keyChangeList: [],
      // dialog弹框-删除弹框
      delId: "",
      centerDialogVisible: false,
      // 删除弹框显示隐藏
      // 活动安排树的数据
      activityTreeData: [],
      defaultTree: {
        children: 'sublevel',
        label: 'name'
      },
      defaultExpandedArr: [],
      chooseActivityTree: [],
      chooseTreeTotal: 0,
      activityTreeState: false,
      // 编辑器的数据
      isEditorShow: false,
      ueidtorName: 'ueditor',
      val: '',
      modeType: 'descript',
      nowArticleData: '',
      itemName: '',
      // 编辑器返回drawData的字段
      // 当前添加/编辑所属的目标领域，关键经验
      nowAreaId: 0,
      nowAreaName: '',
      nowConceptId: 0,
      nowConceptName: '',
      //复制的最后一个

      listData: [],
      isCheckedAll: false,
      Search: Search,
      //search图标
      dialogFormVisible: false,
      //显示弹框
      nameFlag: true,
      //新层级名称
      checkedArr: [],
      //选中的数组
      copyName: "",
      //同步的名字
      list: [{
        id: 1,
        name: "阶段二"
      }]
    };
  },
  components: {},
  created: function created() {
    // this.getAllFun();
    // this.getAllTargetFun();
    this.initActivityTree();
    // this.getCourseAll();
  },
  mounted: function mounted() {
    document.addEventListener("click", this.hideContextmenuDialog);
  },
  methods: {
    getActiveData: function getActiveData() {
      return this.chooseActivityTree;
    },
    setActiveData: function setActiveData(data) {
      this.chooseActivityTree = data;
    },
    /****新加的开始****/
    //获取右边选择的所有活动
    getCategoryActivity: function getCategoryActivity() {
      var _this = this;
      this.service.axiosUtils({
        requestName: 'getCategoryActivity',
        data: {
          courseId: this.courseId,
          categoryId: this.currentRow.id //课程版本id
        },

        sucFn: function sucFn(res) {
          _this.chooseActivityTree = res.data;
          _this.chooseTreeTotal = _this.chooseActivityTree.length;
          _this.replicateIds = [];
          for (var i = 0; i < _this.chooseActivityTree.length; i++) {
            _this.chooseActivityTree[i].name = _this.chooseActivityTree[i].activityName;
            //构建已经被选中的id
            _this.replicateIds.push(_this.chooseActivityTree[i].id);
          }
        }
      });
    },
    changeCheckChild: function changeCheckChild() {
      console.log("值发生改变的时候", this.containSublevel);
      /* if(this.containSublevel == 1){
         this.containSublevel = 0;
         this.$toast.warning("只有活动才可以选择当前选项");
       }*/
    },
    // dialog确定函数
    hdConfirm: function hdConfirm() {
      console.log("确定", this.chooseActivityTree, this.replicateIds);
      this.replicateCourseStructure();
      this.drawerData.activitys = this.chooseActivityTree;
      this.activityTreeState = false;
    },
    setStructureId: function setStructureId(structureId) {
      this.structureId = structureId;
    },
    //设置课程版本活动列表
    replicateCourseStructure: function replicateCourseStructure() {
      var _this2 = this;
      if (this.replicateIds.length == 0) {
        this.$toast.error("至少选择一个");
        return;
      }
      if (this.currentLeftData.stdlevelId != 4) {
        this.containSublevel = 0;
      }
      this.service.axiosUtils({
        requestName: "setCategoryActivity",
        data: {
          userId: this.userInfo.userId,
          roleId: this.userInfo.roleId,
          courseId: this.courseData.id,
          //课程id
          categoryId: this.currentRow.id,
          //课程版本id
          activitys: this.replicateIds //[1,2,3,4]要复制的课程层级列表
        },

        sucFn: function sucFn(res) {
          if (res.status === 1) {
            _this2.$toast.success("修改活动管理成功");
            _this2.hdCancel();
            //重新刷新父级视图
            // this.$parent.initData();
            // this.$parent.getStructuredefinitionDrop();
          } else {
            _this2.$toast.error(res.message);
          }
        }
      });
    },
    //获取所有课程
    getCourseAll: function getCourseAll() {
      var _this3 = this;
      this.service.axiosUtils({
        requestName: "getCourseAll",
        data: {},
        sucFn: function sucFn(res) {
          if (res.status === 1) {
            _this3.courseList = res.data;
            // this.filter[0].list = res.data
          } else {
            _this3.$toast.error(res.message);
          }
        }
      });
    },
    // dialog取消函数
    hdCancel: function hdCancel() {
      this.chooseActivityTree = [];
      this.replicateIds = [];
      this.activityTreeState = false;
      this.chooseTreeTotal = 0;
      this.cancelDrawer();
    },
    // dialog左边树枝右键显示下拉框让选择
    showContextmenuDialog: function showContextmenuDialog(e, data, n, t) {
      // console.log(e,data,n,t)
      this.contextmenuDialog.left = e.clientX;
      this.contextmenuDialog.top = e.clientY;
      this.isShowContextmenuDialog = true;
      this.checkedData = data;
    },
    //隐藏右键菜单
    hideContextmenuDialog: function hideContextmenuDialog() {
      this.isShowContextmenuDialog = false;
    },
    //复制子层级
    childTreeClick: function childTreeClick() {
      var data = this.checkedData;
      // console.log("复制子层级的数据",data);
      //检查是否是同一个结构类型
      if (!this.checkStdLevelSame(data.sublevel[0])) {
        this.$toast.warning("请选择活动");
        return;
      }
      for (var i = 0; i < data.sublevel.length; i++) {
        this.forCheckedRightData(data.sublevel[i]);
      }
      //隐藏右键菜单
      this.hideContextmenuDialog();
    },
    //判断复制的层级是否是同一个结构类型
    checkStdLevelSame: function checkStdLevelSame(rightData) {
      console.log("检查是否是同一个结构类型的数据", this.currentLeftData, rightData);

      //是否是活动
      if (rightData.standard.stdlevelValue == 4) {
        return true;
      }
      /* if(this.currentLeftData.stdlevelId == rightData.standard.stdlevelValue){
         return true;
       }*/
      return false;
    },
    //复制当前层级
    activityTreeClick: function activityTreeClick() {
      var data = this.checkedData;
      //检查是否是同一个结构类型
      if (!this.checkStdLevelSame(data)) {
        this.$toast.warning("请选择活动");
        return;
      }
      var tmpData = {
        id: data.id,
        name: data.name
      };
      this.forCheckedRightData(tmpData);
      //隐藏右键菜单
      this.hideContextmenuDialog();
    },
    //往右边框里面添加数据
    forCheckedRightData: function forCheckedRightData(tmpData) {
      for (var i = 0; i < this.chooseActivityTree.length; i++) {
        if (this.chooseActivityTree[i].id === tmpData.id) {
          this.$toast.error("已经加入选择列表");
          return;
        }
      }
      this.chooseActivityTree.push(tmpData);
      this.replicateIds.push(tmpData.id);
      this.chooseTreeTotal = this.chooseActivityTree.length;
    },
    // dialog右边删除函数
    delChooseTree: function delChooseTree(data, index) {
      this.chooseActivityTree.splice(this.chooseActivityTree.indexOf(data), 1);
      this.replicateIds.splice(index, 1);
      this.chooseTreeTotal = this.chooseActivityTree.length;
    },
    // dialog左边初始化树函数
    initActivityTree: function initActivityTree() {
      var _this4 = this;
      // structureName:"", 名称关键字
      this.service.axiosUtils({
        requestName: 'coursestructureGetAll',
        data: {
          courseId: this.courseId
        },
        sucFn: function sucFn(res) {
          _this4.activityTreeData = res.data;
          // 展开数组
          _this4.activityTreeData.forEach(function (item) {
            _this4.defaultExpandedArr.push(item.id);
          });
        }
      });
    },
    /****新加的结束****/
    // =============活动选择dialog相关函数 start  =============
    // =================== 初始化数据 start===========================
    getAllTargetFun: function getAllTargetFun() {
      var _this5 = this;
      this.service.axiosUtils({
        requestName: 'getTargetareaAllList',
        data: {},
        sucFn: function sucFn(res) {
          _this5.treeChangeList = res.data;
        }
      });
    },
    getAllKeyFun: function getAllKeyFun(num) {
      var _this6 = this;
      // getKeyExpList
      this.service.axiosUtils({
        requestName: 'getKeyExpList',
        data: {
          areaId: num
        },
        sucFn: function sucFn(res) {
          _this6.keyChangeList = res.data;
        }
      });
    },
    //获取树状结构
    getAllFun: function getAllFun() {
      var _this7 = this;
      this.service.axiosUtils({
        requestName: 'getTargetareaChildList',
        data: {},
        sucFn: function sucFn(res) {
          _this7.originTreeData = res.data;
          // 有所有
          if (!_this7.$route.query.tree) {
            var allTree = [{
              id: 0,
              name: "所有",
              children: res.data
            }];
            _this7.treeData = allTree;
            _this7.currentTree = _this7.treeData[0];
            _this7.changeRouter(_this7.currentTree);
          } else {
            _this7.treeData = res.data;
            _this7.currentTree = JSON.parse(_this7.$route.query.tree);
            _this7.changeRouter();
          }
          _this7.nowCheckData = _this7.currentTree;

          /* this.$nextTick(() => {
             this.$refs.tree.setCurrentKey(this.currentTree.id);
           })*/
        }
      });
    },
    //给其他组件使用的设置当前的tree
    setCurrentTree: function setCurrentTree(value) {
      this.currentTree = value;
    },
    //路由改变执行的方法
    changeRouter: function changeRouter(json) {
      if (this.$route.path !== '/objective/LearningTrajectory') return;
      if (!json) {
        this.currentTree = this.$route.query.tree ? JSON.parse(this.$route.query.tree) : {};
      }
      this.filter[1].value = this.$route.query.resName || "";
      this.filter[0].value = this.$route.query.state || "";
      this.filterJson = {
        resName: this.filter[1].value,
        state: this.filter[0].value
      };
      this.initIds();
      this.initData();
    },
    // 初始化目标领域及关键经验id函数
    initIds: function initIds() {
      if (!this.currentTree.id) {
        // 所有的情况
        this.nowAreaId = 0;
        this.nowAreaName = '';
        this.nowConceptId = 0;
        this.nowConceptName = '';
      } else {
        if (this.currentTree.children) {
          // 选中是：非关键经验情况，目标领域
          this.nowAreaId = this.currentTree.id;
          this.nowAreaName = this.currentTree.name;
          this.nowConceptId = 0;
          this.nowConceptName = '';
        } else {
          // 选中是：关键经验
          // 目标领域id及名称
          for (var i = 0; i < this.originTreeData.length; i++) {
            if (parseInt(this.currentTree.areaId) === parseInt(this.originTreeData[i].id)) {
              this.nowAreaId = this.originTreeData[i].id;
              this.nowAreaName = this.originTreeData[i].name;
              continue;
            }
          }
          // 关键经验id及名称
          this.nowConceptId = this.currentTree.id;
          this.nowConceptName = this.currentTree.name;
        }
      }
    },
    // 初始化表格数据
    initData: function initData() {
      var _this8 = this;
      this.service.axiosUtils({
        requestName: "getLearningpathList",
        data: {
          areaId: this.nowAreaId,
          conceptId: this.nowConceptId,
          pageSize: this.pageSize,
          pageIndex: this.currentPage,
          title: this.filter[1].value,
          state: this.filter[0].value
        },
        sucFn: function sucFn(res) {
          if (res.status === 1) {
            _this8.tableData = res.data.data;
            _this8.total = res.data.total;
          } else {
            _this8.$toast.error(res.message);
          }
        }
      });
    },
    // page-页码修改pagesize
    handleSizeChange: function handleSizeChange(page) {
      this.pageSize = page;
      this.initData();
    },
    // page-页码修改当前页码
    handleCurrentChange: function handleCurrentChange(page) {
      this.currentPage = page;
      this.initData();
    },
    //筛选功能
    search: function search(filterArr) {
      var _this9 = this;
      this.service.axiosUtils({
        requestName: "getLearningpathList",
        data: {
          title: filterArr[1].value,
          state: filterArr[0].value,
          areaId: this.nowAreaId,
          conceptId: this.nowConceptId,
          pageSize: this.pageSize,
          pageIndex: this.currentPage
        },
        sucFn: function sucFn(res) {
          if (res.status === 1) {
            _this9.tableData = res.data.data;
            _this9.total = res.data.total;
          } else {
            _this9.$toast.error(res.message);
          }
        }
      });
    },
    //重置
    reload: function reload() {
      this.filter[0].value = "";
      this.filter[1].value = "";
      this.initData();
    },
    //新建初始化数据
    cleardrawerData: function cleardrawerData() {
      this.drawerData = {
        areaId: '',
        conceptId: '',
        title: "",
        performance: '',
        //典型表现
        case: '',
        //案例展示
        activitys: [],
        //活动列表
        rank: "",
        state: 1
      };
      if (this.$refs.form) this.$refs.form.resetFields();
    },
    selectTargetChanged: function selectTargetChanged(val) {
      console.log(val);
      this.drawerData.areaId = val;
      this.keyChangeList = [];
      this.drawerData.conceptId = '';
      this.getAllKeyFun(val);
    },
    // =================== 页面目标领域关键经验树 相关函数 start===========================
    //点击某一个节点
    handleNodeClick: function handleNodeClick(data) {
      this.nowCheckData = _objectSpread({}, data);
      this.currentTree = _objectSpread({}, data);
      // delete this.currentTree.children
      if (this.currentTree.children) this.currentTree.children = [];
      var queryData = _objectSpread({}, this.$route.query);
      queryData.tree = JSON.stringify(this.currentTree);
      this.$router.replace({
        path: this.$route.path,
        query: queryData
      });
    },
    //点击展开收起
    isCollapseFn: function isCollapseFn() {
      if (this.isCollapse) {
        this.isCollapse = false;
      } else {
        //展开
        this.isCollapse = true;
      }
    },
    //  =================== 添加/编辑教-学路径 抽屉 相关函数  ===================
    // 添加函数
    addFun: function addFun() {
      this.cleardrawerData();
      if (!this.currentTree.id || this.nowCheckData.children && this.nowCheckData.children.length >= 0) {
        this.$toast.error('当前选项不能添加教-学路径');
        return;
      } else {
        this.initIds();
        if (!this.drawerData.areaId || !this.drawerData.conceptId) {
          this.drawerData.areaId = this.nowAreaId;
          this.drawerData.conceptId = this.nowConceptId;
          this.getAllKeyFun(this.drawerData.areaId);
        }
        this.drawerState = "add";
        this.drawer = true;
      }
    },
    // table-中操作函数
    detail: function detail(type, val) {
      if (type === "edit") {
        // this.nowAreaId = val.area.id
        this.nowAreaName = val.area.areaName;
        // this.nowConceptId = val.concept.id
        this.nowConceptName = val.concept.conceptName;
        this.drawerData = {
          areaId: val.areaId,
          rank: val.rank,
          state: val.state,
          conceptId: val.conceptId,
          title: val.title,
          performance: val.performance,
          //典型表现
          case: val.case,
          //案例展示
          activitys: val.activitys //活动列表
        };

        this.drawerData.id = val.id;
        this.keyChangeList = [];
        this.getAllKeyFun(val.areaId);
        this.drawerState = "edit";
        this.drawer = true;
      } else if (type === "del") {
        this.centerDialogVisible = true;
        this.delId = val.id;
      } else {}
    },
    confirmClick: function confirmClick(formName) {
      var _this10 = this;
      var that = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          var url = "";
          if (that.drawerState === "edit") {
            //修改
            url = "editLearningpath";
          } else {
            //添加
            url = "addLearningpath";
          }
          for (var i = 0; i < _this10.drawerData.activitys.length; i++) {
            _this10.drawerData.activitys[i].rank = i + 1;
          }
          that.service.axiosUtils({
            requestName: url,
            data: that.drawerData,
            sucFn: function sucFn(res) {
              if (res.status === 1) {
                that.$toast.success(res.message);
                that.drawer = false;
                if (that.drawerState === "add") {
                  that.currentPage = 1;
                }
                that.initData();
                that.cleardrawerData();
              } else {
                that.$toast.error(res.message);
              }
            }
          });
        }
      });
    },
    cancelClick: function cancelClick() {
      this.drawer = false;
      this.drawerState = "";
    },
    // 显示弹框函数
    showDialog: function showDialog(type, model) {
      if (type === 'performance' || type === 'case') {
        this.nowArticleData = {
          'content': model
        };
        this.itemName = type;
        this.isEditorShow = true;
      } else if (type === 'activity') {
        this.activityTreeState = true;
      } else {}
    },
    //====================== table 操作函数 =======================
    // table-修改正常/禁用状态
    editState: function editState(row) {
      var _this11 = this;
      var rowState = "";
      if (row.state === 1) {
        rowState = -1;
      } else {
        rowState = 1;
      }
      this.service.axiosUtils({
        requestName: "changeLearningpathState",
        data: {
          id: row.id,
          state: rowState
        },
        sucFn: function sucFn(res) {
          if (res.status === 1) {
            _this11.$toast.success(res.message);
            if (row.state === 1) row.state = -1;else row.state = 1;
          } else {
            _this11.$toast.error(res.message);
          }
        }
      });
    },
    // dialog弹框-删除弹框确定函数
    confirmFun: function confirmFun() {
      var _this12 = this;
      //删除当前的资源类型
      this.service.axiosUtils({
        requestName: "deleteLearningpath",
        data: {
          id: this.delId
        },
        sucFn: function sucFn(res) {
          if (res.status === 1) {
            _this12.$toast.success(res.message);
            _this12.delId = "";
            _this12.centerDialogVisible = false;
            _this12.initData();
          } else {
            _this12.$toast.error(res.message);
          }
        }
      });
    },
    //选中单个
    checkedItem: function checkedItem(value) {
      //是否全部选中，是的话就把全部选中设置为true
      this.isCheckedAll = this.isCheckedAllFn();
      this.checkedArr = this.getChecked();
    },
    //判断是否是全部选中了
    isCheckedAllFn: function isCheckedAllFn() {
      var isCheckedAll = false;
      for (var i = 0; i < this.listData.length; i++) {
        if (!this.listData[i].isChecked) {
          return isCheckedAll;
        }
      }
      isCheckedAll = true;
      return isCheckedAll;
    },
    //全选中 全不选中
    checkedAll: function checkedAll(value) {
      for (var i = 0; i < this.listData.length; i++) {
        this.listData[i].isChecked = this.isCheckedAll;
      }
    },
    //取消弹框
    cancelDrawer: function cancelDrawer() {
      //关闭弹框
      this.dialogFormVisible = false;
      this.isCheckedAll = false;
      this.nameFlag = true;
      this.checkedArr = [];
      //隐藏右键菜单
      this.hideContextmenuDialog();
    },
    //展示弹框
    showDrawer: function showDrawer(postData, row) {
      this.currentRow = row;
      this.propShowData = postData;
      // this.copyName=this.currentRow.name;
      /*if(postData){
        this.getParent(postData)
      }*/
      //展示弹框
      this.dialogFormVisible = true;
      //获取所有活动
      this.getCategoryActivity();
    },
    //获取所有选中的父级
    getChecked: function getChecked() {
      var arr = [];
      for (var i = 0; i < this.listData.length; i++) {
        if (this.listData[i].isChecked) {
          arr.push(this.listData[i].id);
        }
      }
      this.checkedArr = arr;
      return arr;
    },
    //确定同步到
    copyCourseStructureTo: function copyCourseStructureTo() {
      var _this13 = this;
      var postData = this.propShowData;
      //获取所有选中的
      postData.structureIds = this.getChecked();
      postData.logo = this.currentRow.logo;
      postData.isClass = this.currentRow.isClass;
      postData.description = this.currentRow.description;
      postData.haveFree = this.currentRow.haveFree;
      postData.recommend = this.currentRow.recommend;
      postData.rank = this.currentRow.rank;
      postData.state = this.currentRow.state;
      if (this.nameFlag) {
        postData.toName = this.copyName;
      } else {
        postData.toName = this.currentRow.name;
      }
      this.service.axiosUtils({
        requestName: "copyCourseStructureTo",
        data: postData,
        sucFn: function sucFn(res) {
          if (res.status === 1) {
            _this13.$toast.success("同步层级成功");
            _this13.cancelDrawer();
            //更新父级视图
            _this13.$parent.initData();
          } else {
            _this13.$toast.error(res.message);
          }
        }
      });
    }
  }
};